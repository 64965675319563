.mapLocation {
    width: 600px;
    height: 300px;
    border-radius: 2px;
}
@media screen and (max-width: 480px) {
    .mapLocation {
        width: 300px;
    }
}

@media screen and (min-width: 480px) {
    .mapLocation {
        width: 400px;
    }
}

@media screen and (min-width: 720px) {
    .mapLocation {
        width: 470px;
    }
}